<template>
  <v-navigation-drawer v-model="drawer" app floating temporary style="font-family: MontserratRegular; font-weight:bolder!important; overflow: visible!important;" height="100vh">
    <v-container style="height: 100vh;" fluid>
      <v-row>
        <!-- Logo Section -->
        <v-col cols="12" class="pa-10 ma-5 py-10 px-10 col-center">
            <v-img src="@/assets/logo b-bg.png" style="cursor: pointer" class="logoImg" width="100" cover></v-img>
        </v-col>
        
        <!-- Menu Section -->
        <v-col cols="12" class="my-12" 
         style="overflow: visible; font-family:montserratRegular; font-weight:bolder!important;">
          <v-list density="compact" nav>
            <template v-for="item in menuItems" :key="item.title">
              <template v-if="item.listItems">
                <v-menu offset-y>
                  <template v-slot:activator="{ props }">
                    <v-btn v-bind="props" variant="text" style="font-family: montserratRegular!important; font-weight:bolder!important;"  class="mb-2 font-weight-bold text-center text-subtitle-1 text-md-subtitle-2" v-ripple.center>
                      {{ item.title }}
                      <v-icon>mdi mdi-menu-down</v-icon>
                    </v-btn>
                  </template>
                  <v-list>
                    <v-list-item v-for="(subItem, index) in item.listItems" :key="index">
                      <v-btn
                      class="no-overlay-btn"
                      style="background: none; font-family: montserratRegular; font-weight:bolder;"
                        :to="getLink(item, subItem)"
                        variant="text"
                        density="compact"
                        size="small"
                      >
                        {{ subItem.title }}
                      </v-btn>
                    </v-list-item>
                  </v-list>
                </v-menu>
              </template>
              <template v-else>
                <v-btn
                  :to="getLink(item)"
                  variant="text" 
                  class="no-overlay-btn mb-2 font-weight-bold text-center text-subtitle-1 text-md-subtitle-2" 
                  v-ripple.center
                  style="font-family: montserratRegular; font-weight:bolder;"
                >
                  {{ item.title }}
                </v-btn>
              </template>
            </template>
          </v-list>
        </v-col>
        
        <!-- Icon Section -->
        <v-col cols="12" class="pt-8" v-show="false">
          <v-row justify="space-around">
            <v-btn v-for="(icons, index) in iconItems" :key="index" class="sm-ml-n4 icon-btn2" :href="icons.a" style="background: transparent!important" target="_blank" rel="noopener noreferrer">
              <v-icon :icon="icons.icon" size="medium" style="cursor: pointer;"></v-icon>
            </v-btn>
          </v-row>
        </v-col>
        
        <!-- Footer -->
        <v-col cols="12" class="">
          <v-footer absolute app style="bottom: 0px;">
            <div class="px-4 text-center w-100">
              <strong>Ottush Delights</strong>
              <sup>
                <v-icon class="mr-2" size="small">mdi mdi-copyright</v-icon>
              </sup>
            </div>
          </v-footer>
        </v-col>
      </v-row>
    </v-container>
  </v-navigation-drawer>    

  <v-app-bar
    :elevation="1"
    scroll-behavior="inverted elevate"
    flat
    app
    :height="$vuetify.display.mdAndDown ? 150 : 70"
  >
    <!-- App Bar Content -->
    <v-container fill-height>
      <v-row
        align="center"
        justify="center"
        class="text-center"
        :class="{ 'flex-column': $vuetify.display.mdAndDown }"
      >
        <!-- Logo Section -->
        <v-col cols="auto" class="img-col" :style="imgColStyle">
          <div class="img-container">
            <v-img
              alt="Ottush Delights Logo - Cocktail and Event Services"
              src="@/assets/logo b-bg.png"
              width="90"
              aspect-ratio="1/1"
              contain
            ></v-img>
          </div>
        </v-col>

        <!-- Menu Section (Hidden on Small Screens) -->
        <v-col cols="auto" class="menu-col pb-0" v-show="$vuetify.display.mdAndUp">
          <div class="text-center">
            <v-menu
              transition="scale-transition"
              v-for="item in menuItems"
              :key="item.title"
              open-on-hover
            >
              <template v-slot:activator="{ props }">
                <v-btn
                class="no-overlay-btn"
                  v-if="item.path.startsWith('#')"
                  :to="{ name: 'home', hash: item.path }"
                  density="compact"
                  v-bind="props"
                  rounded="lg"
                  size="large"
                  variant="text"
                >
                  <v-app-bar-title class="menu-title">{{ item.title }}</v-app-bar-title>
                </v-btn>
                <v-btn
                  v-else-if="item.path === '/'"
                  :to="{ name: 'home' }"
                  class="no-overlay-btn"
                  density="compact"
                  v-bind="props"
                  rounded="lg"
                  size="large"
                  variant="text"
                >
                  <v-app-bar-title class="menu-title">{{ item.title }}</v-app-bar-title>
                </v-btn>
                <v-btn
                  v-else-if="item.path.startsWith('/')"
                  :to="getLink(item)"
                  class="no-overlay-btn"
                  density="compact"
                  v-bind="props"
                  rounded="lg"
                  size="large"
                  variant="text"
                >
                  <v-app-bar-title class="menu-title">{{ item.title }}</v-app-bar-title>
                </v-btn>
              </template>
              <v-list class="list-box" v-if="item.listItems">
                <v-btn
                  v-for="(subItem, index) in item.listItems"
                  :key="index"
                  :to="getLink(item, subItem)"
                  class="no-overlay-btn"
                  density="compact"
                  rounded="lg"
                  variant="text"
                >
                  <v-list-item-title class="list">{{ subItem.title }}</v-list-item-title>
                </v-btn>
              </v-list>
            </v-menu>
          </div>
        </v-col>

        <!-- Icon Section -->
        <v-col cols="auto" class="icon-col pb">
          <v-btn
            v-for="(icons, index) in iconItems"
            :key="index"
            :href="icons.a"
            class="icon-btn"
            target="_blank"
            rel="noopener noreferrer"
          >
            <v-icon :icon="icons.icon" size="large" class="icon"></v-icon>
          </v-btn>
        </v-col>
        <v-col cols="auto">
          <div
          style="transform: scale(50%); padding-top:0;"
            class="d-md-none d-flex toggle"
            @click="toggleDrawer"
            :class="{ act: drawer }"
          >
            <span></span>
            <span></span>
            <span></span>
          </div>
        </v-col>
      </v-row>
    </v-container>
  </v-app-bar>
</template>

<script>
import { scrollToSection } from "../mixins/scrollToSection";
import navMethods from "@/mixins/navMethods";

export default {
  name: "NavBar",
  mixins: [scrollToSection, navMethods],
  props: {
    menuItems: {
      type: Array,
    },
    iconItems: {
      type: Array,
    },
  },
  data() {
    return {
      drawer: false, // State to control sidebar visibility
    };
  },
  computed: {
  imgColStyle() {
    return {
      'padding-top': this.$vuetify.display.mdAndDown ? '25px' : '0px'
    }
  }
},
  methods: {
    toggleDrawer() {
      this.drawer = !this.drawer; // Toggle sidebar visibility
    },
  },
};
</script>

<style scoped>
.subitem-btn {
  font-size: 0.5em;
  overflow: hidden;
  opacity: 0;
  transition: opacity 0.5s;
}
.col-center {
  justify-content: center;
  align-items: center;
}

.v-list-item:hover .subitem-btn {
  opacity: 1;
}

.menu-title {
  text-transform: uppercase !important;
  font-weight:bolder;
  text-align: center;
  font-size: var(--v-theme-subtitle-2-font-size);
}
.flex-column {
  flex-direction: column !important;
}
.img-col,
.menu-col,
.icon-col {
  display: flex;
  align-items: center;
  padding: 0;
}
.menu-col {
  margin-left: 5px;
}
.list {
  text-transform: uppercase;
  text-align: center;
  margin: 0 !important;
  font-size: var(--v-theme-caption-font-size);
  letter-spacing: 0.05em;
}
span.v-btn__content {
  font-family: 'MontserratRegular'!important;
}
.list-box {
  display: flex;
  flex-direction: column;
}
.icon-col {
  display: flex !important;
  flex-wrap: wrap !important;
  z-index: 1;
}
.no-overlay-btn {
  position: relative;
  overflow: hidden;
}
.no-overlay-btn::before,
.no-overlay-btn::after {
  content: none !important;
}

.no-overlay-btn:active,
.no-overlay-btn >>> .v-btn__overlay {
  background-color: transparent !important; /* Remove overlay color */
  box-shadow: none !important;
}
.icon-btn {
  margin: 0 6px;
  padding: 0;
  min-width: auto;
}
.icon-btn2 {
  background: transparent !important;
box-shadow: none !important;
}
.icon-row {
  flex-direction: row !important;
}
.icon {
  cursor: pointer;
}
.sidebar-drawer {
  background-color: #fff9e6; /* Matches the color theme */
}
.toggle{
  transform: scale(50%);
  position: relative;
  width: 75px;
  height: 70px;
  background: transparent;
   box-shadow: 0 10px 20px rgba(92, 92, 92, 0.08);
  border-radius: 10px;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  overflow: hidden;
  z-index: 101;
}

.toggle span{
position: absolute;
width: 40px;
height: 4px;
background: #000;
transition: color 0.5s;
border-radius: 4px;
transition: 0.5s;
}

.toggle span:nth-child(1){
transform: translateY(-15px);
width: 25px;
left: 15px;
}

.toggle.act span:nth-child(1){
width: 40px;
transform: translateY(0px) rotate(45deg);
transition-delay: 0.125s;
}


.toggle span:nth-child(2){
transform: translateY(15px);
width: 15px;
left: 15px;
}

.toggle.act span:nth-child(2){
width: 40px;
transform: translateY(0px) rotate(315deg);
transition-delay: 0.25s;
}

.toggle.act span:nth-child(3){
transform: translateX(60px);
}
</style>
