import { createRouter, createWebHistory } from "vue-router";
import HomeView from "../views/HomeView.vue";
import WhoWeAre from "../views/WhoWeAreView.vue";
import CelebrationPack from "../views/CelebrationPack.vue";
import EventBev from "../views/EventBev.vue"
import SignatureMocktails from "../views/SignatureMocktails.vue"


const routes = [
  {
    path: "/",
    name: "home",
    component: HomeView,
  },
  {
    path: '/whoweare/:sectionId',
    name: 'WhoWeAre',
    component: WhoWeAre,
  },
  {
    path: '/services/CelebrationPackages',
    name: 'CelebrationPackages',
    component: CelebrationPack,
  },
  {
    path: '/services/EventBeverage',
    name: 'EventBeverage',
    component: EventBev,
  },
  {
    path: '/services/SignatureMocktails',
    name: 'SignatureMocktails',
    component: SignatureMocktails,
  }
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
  scrollBehavior(to, from, savedPosition) {
    if (to.hash) {
      return { el: to.hash, behavior: 'smooth' };
    } else if (savedPosition) {
      return savedPosition;
    } else {
      return { top: 0 };
    }
  },
});

export default router;
