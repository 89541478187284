<template>
  <V-container fluid class="yellow-background">
    
    <VRow class="overflow-hidden">
      <VCol cols="12" class="menu-vid" id="home">
        <MenuVid :imageSrc="null" />
      </VCol>   
          <VCol id="WhoWeAre" :class="{ 'xs-weare': $vuetify.display.xs }" cols="12">
      <WeAre />
    </VCol>
    <VCol cols="12">
      <ContinuousSlideshow />
    </VCol>  
        <VCol id="WhatWeDo" cols="12">
      <WhatWeDoVue  />
      </VCol>
      <VCol id="Services" cols="12">
      <ServiceVue :menuItems="menuItems" />
      </VCol>
      <VCol cols="12">
      <CarouselShow />
      </VCol>
      <VCol cols="12">
        <ChatUs />
      </VCol>
    </VRow>
  
  </V-container>
</template>

<script>

import CarouselShow from '@/components/CarouselShow.vue';
import WhatWeDoVue from '@/components/WhatWeDo.vue';
import ServiceVue from '@/components/ServiceVue.vue';
import MenuVid from '@/components/MenuVid.vue';
import WeAre from '@/components/WhoWeAre.vue';
import ContinuousSlideshow from '@/components/ContinuousSlideshow.vue';
import ChatUs from '@/components/ChatUs.vue';
export default {
  name: "HomeView",
  components: {
    MenuVid,
    WeAre,
    ContinuousSlideshow,
    WhatWeDoVue,
    ServiceVue,
    CarouselShow,
    ChatUs
},
  props: {
    menuItems: {
      type: Array,
    },
  },
}
</script>

<style scoped>

.align-services {
  align-items: center!important;
  justify-content: center!important;
}

</style>