export default {
  methods: {
    getLink(item, subItem = null) {
      if (subItem) {
        if (subItem.meta && subItem.meta.routeName) {
          return { name: subItem.meta.routeName };
        } else if (subItem.path.startsWith("/")) {
          return { path: subItem.path };
        } else {
          // Handle param routes
          return { name: item.meta.routeName, params: { sectionId: subItem.title } };
        }
      } else {
        if (item.path.startsWith("#")) {
          return { name: "home", hash: item.path };
        } else if (item.path === "/") {
          return { name: "home" };
        } else if (item.meta && item.meta.routeName) {
          return { name: item.meta.routeName };
        } else {
          return { path: item.path };
        }
      }
    }    
  },
};
