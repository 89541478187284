<template>
    <VLazy
    transition="fade">
      <VContainer class="spaced" :class="{ 'size': $vuetify.display.mdAndDown }">
          <VRow >
              <div>
                <p class="p-tag" :class="{ 'size-md': $vuetify.display.mdAndDown, 'size-sm': $vuetify.display.smAndDown, 'size-xs': $vuetify.display.xs }">What We Do</p>
              </div>
          </VRow>
          <VRow >
              <h5 :class="{ 'size-text': $vuetify.display.mdAndDown }">
                We delight in making every moment special, spreading joy one celebration at a time. With love, care, and attention to detail, we create unforgettable experiences at various intimate, social, and corporate events.
              </h5>
          </VRow>
          <VRow class="divider">
              <VDivider vertical="true" class="border-opacity-80" length="100"></VDivider>
          </VRow>
          <VRow class="h5" :class="{ 'sm-h5': $vuetify.display.mdAndDown }">
          <h5 class="see" :class="{ 'sm-see': $vuetify.display.mdAndDown }">
            See Our Services
          </h5>
      </VRow>
      </VContainer>
    </VLazy>
</template>

<script>
export default {
    name: 'WhatWeDo',
    setup () {
        

        return {}
    }
}
</script>

<style scoped>
.sm-h5{
  justify-content: flex-start!important;
  align-items: flex-start!important;
}

.size-md {
  font-size: 5.5em!important;
}
.size-sm {
  font-size: 4.8em!important;
}
.size-xs {
  font-size: 4em!important;
}
.size-text {
  font-size: 1.5em!important;
}

.p-tag{
    font-size: 9em;
    padding-bottom: 50px;
    text-align: center;
    font-family: NewYork;
    letter-spacing: -0.1em;
}
h5{
  font-family: montserratRegular;
  text-align: center;
  font-size: 2.5em;
  padding: 0;
  margin: 0;
}
.h5{
  margin-top: 30px!important;
  margin-bottom: 50px;
}
.divider{
  margin-top: 60px;
}
.size {
  width: 100%!important;
  height: 70vh!important;
  margin-bottom: 100px;
}
.spaced {
  width: 150%;
  display: flex;
  flex-direction: column;
  align-items: center;
}
.see{
    font-family: ArizoniaRegular;
    text-align: center;
    font-size: 4em;
  }
.sm-see {
  font-size: 3em;
}
</style>