<template>
  <VLazy
  transition="fade">
    <VContainer fluid class="spaced">
        <VRow class="centered-container" :class="{ 'size-container': $vuetify.display.mdAndDown }">
            <div>
              <p class="p-tag" :class="{ 'size-text': $vuetify.display.mdAndDown, 'sm-text': $vuetify.display.smAndDown }">Who <br>We Are</p>
            </div>
        </VRow>
        <VRow class="h5" :class="{ 'size-h5-container': $vuetify.display.mdAndDown }">
           <v-col cols="12"> 
            <h5 :class="{ 'h5-md': $vuetify.display.mdAndDown }">
              At Ottush Delight, we're Nigeria's premium alcohol-free beverage innovators. Our passion lies in crafting exquisite mocktails and providing top-tier drinks management services for events of all sizes.
            </h5>
          </v-col>
          <v-row align="center" justify="center">
            <v-col cols="12" class="d-flex justify-center">
              <v-btn class="-btn" variant="outlined" :to="{ name: 'WhoWeAre', params: { sectionId: 'default' } }"> Find out More</v-btn>
            </v-col>
          </v-row>          
        </VRow> 
    </VContainer>
  </VLazy>
</template>

<script>

export default {
  name: 'WeAre',
  components: {
  },
    setup () {
        

        return {}
    }
}
</script>

<style scoped>
.-btn {
  font-family: montserratRegular;
  font-weight: bolder;
  text-align: center;
}
.p-tag{
    font-size: 9em;
    font-family: NewYork;
    line-height: 110px;
    letter-spacing: -0.1em;
}
.centered-container {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;
  }
.size-text {
  font-size: 5em;
}

h5{
  font-family: montserratRegular;
  
  font-size: 2.5em;
  text-align: center;
}
.h5-md {
  font-size: 1.5em!important;
  padding-top: 20px;
}
.h5{
 
  align-items: center;
  justify-content: center;
}

.spaced{
  margin-top: 15px;
  display: flex;
  flex-direction: column;
  align-items: center;
  height: 60vh;
}

</style>