<template>
  <v-footer absolute app>
    <v-container fluid>
      <v-row>
        <!-- Logo Section -->
        <v-col cols="12" sm="3" md="3" lg="3" class="d-flex justify-center">
          <div class="img-container">
          <v-img 
            src="@/assets/logo b-bg.png" 
            style="cursor: pointer" 
            class="img-col" 
            width="300" 
            cover
          />
        </div>
        </v-col>
        <!-- Menu Section -->
        <v-col cols="12" sm="5" md="5" lg="5">
          <v-row justify="space-between" class="flex-column menu-col">
            <v-col cols="12" class="text-center">
              <v-toolbar-title class="tool-title ml-n4">Navigation</v-toolbar-title>
            </v-col>
            <v-menu
              class="footer-menu"
              transition="scale-transition"
              v-for="item in menuItems"
              :key="item.title"
              open-on-hover
              :close-on-content-click="false"
            >
              <template v-slot:activator="{ props }">
                <v-btn
                  v-bind="props"
                  variant="plain"
                  density="compact"
                  size="small"
                  class="menu-title"
                  style="margin-bottom: 20px"
                  :to="getLink(item)"
                >
                  {{ item.title }}
                </v-btn>
              </template>
              <v-list
                v-if="item.listItems"
                style="background: transparent; box-shadow: none; right: 0; left: auto;"
              >
                <v-list-item v-for="(subItem, index) in item.listItems" :key="index">
                  <v-btn
                    :to="getLink(item, subItem)"
                    class="list"
                    variant="plain"
                    density="compact"
                    size="small"
                  >
                    {{ subItem.title }}
                  </v-btn>
                </v-list-item>
              </v-list>
            </v-menu>
          </v-row>
        </v-col>

        <!-- Icon Section -->
        <v-col cols="12" sm="4" md="4" lg="4" class="pt-8 icon-col text-center">
          <v-row justify="center" class="flex-column">
            <v-col cols="12">
              <v-toolbar-title class="tool-title">Follow us on our Socials</v-toolbar-title>
            </v-col>
            <v-col cols="12" class="d-flex justify-center">
              <v-btn 
                v-for="(icon, index) in iconItems" 
                :key="index" 
                class="icon-btn2" 
                :href="icon.a" 
                style="background: transparent !important" 
                target="_blank" 
                rel="noopener noreferrer"
              >
                <v-icon :icon="icon.icon" size="medium" style="cursor: pointer" />
              </v-btn>
            </v-col>
          </v-row>
        </v-col>        
      </v-row>

      <!-- Copyright Section -->
      <v-row>
        <div class="px-4 text-center w-100 mt-6">
          <strong>Ottush Delights</strong>
          <sup>
            <v-icon class="mr-2" size="small">mdi mdi-copyright</v-icon>
          </sup>
          {{ new Date().getFullYear() }}
        </div>
      </v-row>
    </v-container>
  </v-footer>
</template>

<script>
import navMethods from '@/mixins/navMethods';
export default {
  name: "FooTer",
  mixins: [navMethods],
  props: {
    menuItems: {
      type: Array,
    },
    iconItems: {
      type: Array,
    },
  },
};
</script>

<style scoped>
.img-col, .menu-col, .icon-col {
  display: flex;
  align-items: center;
  padding: 0;
  flex-direction: row;
  justify-content: space-between;
}

.icon-btn2 {
  background: transparent !important;
  box-shadow: none !important;
}

.footer-menu {
  justify-content: space-around;
}

.menu-title {
  text-transform: uppercase !important;
  font-weight: bolder;
  text-align: center;
  font-size: var(--v-theme-subtitle-2-font-size);
  margin-right: 20px;
}

.list {
  text-transform: uppercase;
  text-align: center;
  margin: 0 !important;
  font-size: var(--v-theme-caption-font-size);
  letter-spacing: 0.05em;
  background: transparent;
  box-shadow: none;
}
.tool-title {
  font-weight: bolder;
  padding-left: 0;
  padding: 0;
  margin: 0;
}
</style>
