export const scrollToSection = {
    watch: {
      $route(to) {
        if (to.params.sectionId) {
          const sectionId = to.params.sectionId;
          const element = document.getElementById(sectionId);
          if (element) {
            element.scrollIntoView({ behavior: 'smooth' });
          }
        }
      },
    },
    mounted() {
      this.$nextTick(() => {
        const sectionId = this.$route.params.sectionId;
        const element = document.getElementById(sectionId);
        if (element) {
          element.scrollIntoView({ behavior: 'smooth' });
        }
      });
    },
  };
  