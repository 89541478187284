<template>
  <VContainer class="container" :class="{ 'container-md': $vuetify.display.mdAndDown, 'container-up': $vuetify.display.lgAndUp }">
    <VRow
      v-for="(subItem, index) in servicesListItems"
      :key="subItem.index"
      align="center"
      justify="start"
      class="mb-4"
    >
      <VCol cols="auto" class="text-center" >
        <div class="row">
          <div class="number-wrapper ">
            <span class="number-text">0{{ index + 1 }}</span>
          </div>
          <div class="content" :class="{ 'md-content': $vuetify.display.mdAndDown }">
            <v-btn
              variant="text"
              small
              :to="{ path: subItem.path }"
              class="subitem-btn"
              style="font-weight: bolder!important;"
              :class="{ 'md-content': $vuetify.display.mdAndDown, 'sm-content' :$vuetify.display.smAndDown }"
            >
              {{ subItem.title }}
              <v-icon small left class="arrow-icon">mdi:mdi-arrow-right</v-icon>
            </v-btn>
            <div class="subtitles ">
              <span
                v-for="(subtitle, i) in subItem.subtitleItems"
                :key="i"
                class="subtitle"
              >
                {{ subtitle.subtitle }}
              </span>
            </div>
          </div>
        </div>
        <v-divider></v-divider>
      </VCol>
    </VRow>
  </VContainer>
</template>

<style scoped>
.container-up {
  flex-wrap: wrap;
  align-content: center;
}
.container {
  padding: 20px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  max-width: 100vw;
  text-align: start;
}

.row {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
}

.arrow-icon {
  font-size: 18px;
 
}

.number-wrapper {
  display: flex;
  justify-content: flex-start;
  width: 40px;
  margin-right: 20px;
}

.container-md {
  margin-top:100px;
}

.number-text {
  font-size: 3em;
  font-family: NewYork;
}

.subitem-btn {
  font-size: 0.9em;
  font-weight: bolder;
  text-align: start;
  justify-content: flex-start;
  transition: font-size 0.3s ease-in-out;
}

.md-content {
  font-size: 0.8em;
}
.md-content:hover {
  font-size: 0.9em;
}

.sm-content {
  font-size: 0.7em;
}
.sm-content:hover {
  font-size: 0.75em;
}
.subitem-btn:hover {
  font-size: 1em;
}
.content {
  display: flex;
  flex-direction: column;
  width: 100%;
}

.subtitles {
  display: flex;
  width: 100%;
  flex-direction: row;
  justify-content: flex-start;
  flex-wrap: wrap;
  margin-top: 10px;
  padding-left: 12px;
  font-weight: bolder;
}

.subtitle {
  font-size: 0.9em;
  font-weight: bolder;
  margin: 3px;
}
</style>

<script>
export default {
  name: "serviceVue",
  props: {
    menuItems: {
      type: Array,
    },
  },
  computed: {
    servicesListItems() {
      return this.menuItems.find((item) => item.title === "Services").listItems;
    },
  },
};
</script>
