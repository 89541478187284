<template>
    <VContainer fluid class="container">
        <VLazy
        :min-height="auto"
        transition="scroll-x-transition"
        :options="{'threshold':0.5}">
        <VRow>
            <VCol id="WeAre" cols="auto">
                <div class="content">
                    <h4 class="title">
                        Who We Are
                    </h4>
                    <VLazy
                    :min-height="auto"
                    transition="slide-x-reverse-transition"
                    :options="{'threshold':0.5}">
                    <p style="text-align: center;">At Ottush Delight, we're Nigeria's premium alcohol-free beverage innovators. Our passion lies in crafting exquisite mocktails and providing top-tier drinks management services for events of all sizes. From corporate gatherings to intimate celebrations, we bring creativity, quality, and a touch of magic to every occasion.
                        Our team of expert mixologists and event specialists is dedicated to proving that alcohol-free doesn't mean flavor-free. With our stunning mocktails, candy floss stations, and chocolate fountains, we're redefining the art of celebration in Nigeria.
                        </p>
                    </VLazy>
                </div>
            </VCol>
            <VCol id="OurBrandStory" cols="auto">
                <div class="content">
                    <h4 class="title">
                        Our Brand Story
                    </h4>
                    <VLazy
                    :min-height="auto"
                    transition="slide-x-reverse-transition"
                    :options="{'threshold':0.5}">
                    <p style="text-align: center;">Ottush Delight was born from a simple yet powerful idea: everyone deserves to feel included and indulged at social gatherings, regardless of their choice to consume alcohol. Our founder, Bodunrin Ottu, recognized a gap in Nigeria's event industry for sophisticated, non-alcoholic options that could rival their spirited counterparts.
                        Drawing from his background in biochemistry and a passion for innovative beverages, Bodunrin set out to create a company that would revolutionize how Nigeria celebrates. What started as a small operation mixing unique mocktails for friends and family quickly grew into a full-fledged beverage management service, catering to diverse events across the country.
                        </p>
                    </VLazy>
                </div>
            </VCol>
            <VCol id="MeetOurFounder">
                <div class="content d.flex">
                    <h4 class="title">
                        Meet Our Founder
                    </h4>
                    </div>
            <VRow class="mt-4">
                <VCol  :cols="$vuetify.display.mdAndDown ? 12 : 8">
                    
                    <div class="content">
                        <VLazy
                    :min-height="auto"
                    transition="slide-x-reverse-transition"
                    :options="{'threshold':0.5}">
                    <p style="text-align: start;"> <Span>Bodunrin Ottu - Founder & CEO</Span><br>
                        Bodunrin is the visionary behind Ottush Delight. With a keen understanding of flavor profiles and a commitment to exceptional customer experiences, he has led the company to an enviable position in the Nigeria's event industry.
                        Bodunrin's expertise in Molecular Biology and Biochemistry allows him to approach mocktail creation with scientific precision, resulting in perfectly balanced and innovative drink options. His passion for inclusivity and celebration drives Ottush Delight's mission to transform events, one delightful sip at a time.
                        Under Bodunrin's leadership, Ottush Delight continues to grow, innovate, and set new standards in the world of alcohol-free beverages and event management.</p>
                        </VLazy>
                </div>
            </VCol>
                <VCol class="img">
                    <VLazy
                    :min-height="auto"
                    :transition="$vuetify.display.smAndDown ? slide-y-reverse-transition : slide-x-reverse-transition"
                    :options="{'threshold':0.5}">
                <div class="">
                    <VImg
                    :aspect-ratio="1"
                    src="/Mr Ottush.jpg"
                    :width="$vuetify.display.smAndDown ? 300 : 500">
                    <template v-slot:placeholder>
                        <v-row align="center" class="fill-height ma-0" justify="center">
                          <v-progress-circular color="grey-lighten-5" indeterminate></v-progress-circular>
                        </v-row>
                      </template>
                    </VImg>
                </div>
                </VLazy>
            </VCol>
        </VRow>
      
            </VCol>
        </VRow>
    </VLazy>
    </VContainer>
</template>

<script>
import { scrollToSection } from '@/mixins/scrollToSection';
    export default {
        name: 'WhoWeAre',
        mixins: [scrollToSection],
        
    }
</script>

<style scoped>
.container {
    height: auto;
}
.title {
    font-size: 3em!important;
    font-family: NewYork;
    text-align: center;
}
span {
    font-weight: 400;
    font-size: 2.5rem;
}
p {
    line-height: 2.3rem;
    letter-spacing: 2px;
    font-size: 1.4em;
}
.img {
    display: flex;
    justify-content: center;
    align-items: center;
}
</style>