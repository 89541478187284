<template>
    <v-container fluid>
        <v-row class="overflow-hidden">
            <v-col cols="12" class="menu-vid">
                <MenuVid :imageSrc="imagePack" :overlayText="overlayMessages" />
            </v-col>
        </v-row>
        <v-row justify="center" align="center">
            <v-col class="text-center" cols="12">

                <p class="font-format" :class="{ 'font-size-lg': $vuetify.display.smAndUp }">
                    Make your special day truly memorable with our customised drink and treat packages. 
                    Whether you're celebrating a wedding, engagement, birthday, baby shower, or gender reveal, 
                    we'll provide you with delicious products and services that will leave a lasting impression on you and your guests.
                </p>
            </v-col>
            <v-col>
                <ChatUs />
            </v-col>
        </v-row>
    </v-container>
</template>

<script>
import MenuVid from '@/components/MenuVid.vue';
import ChatUs from '@/components/ChatUs.vue'
    export default {
        name : "CelebrationPack",
        components : {
            MenuVid,
            ChatUs,
        },
        data () {
            return {
                overlayMessages: ["Celebration Packages",],
                imagePack: '/images/jpg/3.jpg'
            }
        }
    }
</script>

<style scoped>
.title {
    font-size: 3em!important;
    font-family: NewYork;
    text-align: center;
}
</style>