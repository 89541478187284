  <template>
    <div style="position: relative;">
      <!-- Video or Image Container -->
      <div v-if="imageSrc" class="video-container" :class="{ 'md-down-video-container': $vuetify.display.mdAndDown, 'sm-down-video-container': $vuetify.display.smAndDown }">
        <!-- Show image when imageSrc is provided -->
        <img :src="imageSrc" class="video-element" alt="Image of Ottush Delight finest services" />
      </div>
      <div v-else class="video-container" :class="{ 'md-down-video-container': $vuetify.display.mdAndDown, 'sm-down-video-container': $vuetify.display.smAndDown }">
        <!-- Show video when no imageSrc is provided -->
        <video
          ref="videoPlayer"
          class="video-element"
          loop
          muted
          preload="auto"
          playsinline
        ></video>
      </div>

      <!-- Overlay Text -->
      <div v-if="showOverlay" class="overlay">
        <h2>
            <div :class="{ 'md-down-overlay-text': $vuetify.display.mdAndDown, 'sm-down-overlay-text': $vuetify.display.smAndDown }" v-for="(text, index) in overlayText" :key="index" class="overlay-text-item">
              {{ text }}
            </div>
        </h2>
      </div>
    </div>
  </template>

  <script>
  export default {
    name: "MenuVid",
    props: {
      videos: {
        type: Array,
        required: false,
        default: () => [
          { src: "https://res.cloudinary.com/dqvzmuz7i/video/upload/f_auto:video,q_auto/q1xl3wbizezk8xpmqzqs" },
          { src: "https://res.cloudinary.com/dqvzmuz7i/video/upload/f_auto:video,q_auto/iaoumehwo25qwb01nbsi" },],  // Default to an empty array if not provided
      },
      imageSrc: {
        type: String,
        required: false,
        default: null,
      },
      overlayText: {
        type: Array,
        required: false,
        default: () => ["Welcome, let's raise a glass", "To a delightful experience"],
      },
    },
    data() {
      return {
        selectedVideoIndex: 0,
        showOverlay: true,
      };
    },
    mounted() {
      if (!this.imageSrc && this.videos.length > 0) {
        // Only setup video if no imageSrc is provided and videos are available
        this.selectRandomVideo(); // Select a random video
        this.setupLazyLoading();  // Setup lazy loading using IntersectionObserver
      }
    },
    methods: {
      selectRandomVideo() {
        if (this.videos.length > 0) {
          this.selectedVideoIndex = Math.floor(Math.random() * this.videos.length);
        }
      },
      setupLazyLoading() {
        const observer = new IntersectionObserver(
          (entries) => {
            entries.forEach((entry) => {
              if (entry.isIntersecting) {
                this.loadVideo(); // Load video when it is in view
                observer.unobserve(entry.target); // Unobserve after loading
              }
            });
          },
          {
            threshold: 0.5, // Adjust threshold as needed
          }
        );

        observer.observe(this.$refs.videoPlayer); // Observe video element
      },
      loadVideo() {
        const videoPlayer = this.$refs.videoPlayer;
        videoPlayer.src = this.videos[this.selectedVideoIndex].src; // Set the video source
        videoPlayer.autoplay = true; // Set autoplay attribute
        videoPlayer.play().catch((error) => console.log('Autoplay failed:', error)); // Attempt to play and catch errors
      },
    },
  };
  </script>

  <style scoped>
  .video-container {
    height: 750px;
    background: #313131;
    opacity: 0.655;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-left: -25%;
    margin-right: -25%;
    width: 150%;
  }
  .md-down-video-container {
    height: 650px;
  }
  .sm-down-video-container {
    height: 550px;
  }
  .overlay-text-item {
    white-space: pre-line;
    font-size: 4em;
    color: white;
    text-transform: uppercase;
    font-weight:lighter;
    text-align: center;
    font-family: NewYork;
    margin-bottom: 20px; 
  }
  .md-down-overlay-text {
    font-size: 3em !important;
    line-height: 90px;
    font-weight: lighter !important;
  }
  .sm-down-overlay-text {
    font-size: 2em!important;
  }
  .video-element {
    object-fit: cover !important;
    width: 100%;
    height: 100%;
  }

  .overlay {
    position: absolute;
    width: 100%;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }

  .overlay-text {
    color: white;
    font-size: 4em;
    text-transform: uppercase;
    font-weight: 200;
    text-align: center;
    font-family: NewYork;
  }
  </style>
