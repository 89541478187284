<template>
 <v-app>
  <v-main>
  <nav-bar :menuItems="menuItems" :iconItems="iconItems" />
  <div class="yellow-background">
    <SpeedInsights/>
  <router-view :menuItems="menuItems" :iconItems="iconItems" />
    <FooterView :menuItems="menuItems" :iconItems="iconItems" />
    </div>
</v-main>
  </v-app>
</template>

<style>
#app {
  font-family: montserratRegular;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  width: 100vw;
  font-weight: bolder;

}
.yellow-background {
  margin-top: -15px;
}
.font-format {
  font-family: montserratRegular;
  font-weight: bolder;
}
.font-size-lg {
  font-size: 1.5em
}
.menu-vid {
  width: 100%;
}
.overflow-hidden {
  overflow: hidden;
  margin-left: -10px;
}
</style>

<script>
import { SpeedInsights } from "@vercel/speed-insights/vue"
import '@fortawesome/fontawesome-free/css/all.css'
import FooterView from './components/FooTer.vue';
import NavBar from './components/NavBar.vue';

export default {
  components: {
    NavBar,
    FooterView,
    SpeedInsights
  },
    data() {
  return {
    menuItems: [
      { title: 'Home', path: '/',   meta: {
      routeName: 'home',
    },
},
      {
        title: 'Who we are',
        path: '#WhoWeAre',
        meta: { routeName: 'WhoWeAre' },
        listItems: [
          { title: 'Our Brand Story', path: '/whoweare/OurBrandStory' },
          { title: 'Meet Our Founder', path: '/whoweare/MeetOurFounder' },
        ],
      },
      { title: 'What we do', path: '#WhatWeDo' },
      {
        title: 'Services',
        meta: { routeName: 'Services' },
        path: '#Services',
        listItems: [
          {
            title: 'Signature Mocktails & Sweet Treats',
            path: '/services/SignatureMocktails',
            subtitleItems: [
              { subtitle: 'Alcohol-free cocktails for all occasions,' },
              { subtitle: 'Candyfloss stations,' },
              { subtitle: 'Chocolate fountain experiences,' },
              { subtitle: 'Customized mocktail menus for your event' },
            ],
          },
          {
            title: 'Event Beverage Management',
            path: '/services/EventBeverage',
            subtitleItems: [
              { subtitle: 'Corporate events and product launches,' },
              { subtitle: 'Trade shows and marketing activations,' },
              { subtitle: 'Creative mobile bar setups,' },
              { subtitle: 'Professional bartending services' },
            ],
          },
          {
            title: 'Celebration Packages',
            path: '/services/CelebrationPackages',
            subtitleItems: [
              { subtitle: 'Weddings and vow renewals,' },
              { subtitle: 'Engagement parties and bridal showers,' },
              { subtitle: 'Birthday celebrations (for kids and adults),' },
              { subtitle: 'Baby showers and gender reveal parties' },
            ],
          },
        ],
      },
    ],
    iconItems: [
      { name: 'Instagram', icon: 'mdi:mdi-instagram', a: 'https://www.instagram.com/ottushdelight/', at: 'Check Our Instagram @ottushdelight' },
      {
        name: 'Whatsapp',
        icon: 'mdi:mdi-whatsapp',
        a: `https://wa.me/2348137928778?text=${encodeURIComponent(`
Hi Ottush, I want to get a qoute for my next event
1. Event date:
2. Venue/proposed location:
3. Expected number of guests:
Our range of services include:
- mocktails,
- drinks management,
- candy floss, popcorn, chocolate fountain,
- others (specify)
4. Service Needed:
5. Other things you want us to know about your event:`)}`,
        at: 'Chat us Now'
      },
      { name: 'Email', icon: 'mdi:mdi-email', a: 'mailto:Ottushdelight@gmail.com', at: 'Mail us Now' },
      { name: 'Tiktok', icon: 'fa-brands fa-tiktok', a: 'https://www.tiktok.com/@ottushdelight', at: 'Check our Tiktok @ottushdelight' },
      { name: 'Phone No', icon: 'mdi:mdi-phone', a: 'tel:+2348137928778', at: 'Call us Now' }
    ],
  };
},

}
</script>