<template>
    <v-container class="quote-section" fluid>
      <v-row justify="center" align="center">
        <v-col cols="12" md="8">
          <v-card class="quote-card" elevation="0">
            <p class="quote-title" :class="{'qoute-text': $vuetify.display.smAndDown}">
              Tell us about your next Event and get a quote
            </p>
            <v-card-actions class="quote-actions">
              <!-- Button with WhatsApp Accent -->
              <v-btn prepend-icon="mdi:mdi-whatsapp" variant="text" rounded="lg" size="large" class="whatsapp-button" @click="redirectToWhatsApp">
                 Get a Quote
              </v-btn>
            </v-card-actions>
          </v-card>
        </v-col>
      </v-row>
    </v-container>
  </template>
  
  <script>
  export default {
    name: 'GetQuoteSection',
    methods: {
      redirectToWhatsApp() {
        const phoneNumber = '2348137928778';
        const message = `
Hi Ottush, I want to get a qoute for my next event
  1. Event date: 
  2. Venue/proposed location: 
  3. Expected number of guests: 
  Our range of services include:
  - mocktails, 
  - drinks management, 
  - candy floss, popcorn, chocolate fountain, 
  - others (specify)
  4. Service Needed: 
  5. Other things you want us to know about your event:`;
        const url = `https://wa.me/${phoneNumber}?text=${encodeURIComponent(message)}`;
        window.open(url, '_blank'); 
      }
    }
  }
  </script>
  
  <style scoped>
  .quote-section {
    margin-top: 50px;
    text-align: center;
    padding: 30px 0;
  }
  .qoute-text {
    font-size: 0.75em!important;
    padding: 0!important;
  }
  .quote-card {
    border-radius: 12px;
    padding: 30px;
    background: transparent;
    transition: all 0.3s ease;
  }
  
  .quote-title {
    font-size: 1.8em;
    font-family: montserratRegular;
    font-weight: bold;
    text-align: center;
    margin-bottom: 20px;
  }
  
  .quote-actions {
    display: flex;
    justify-content: center;
  }
  
  .whatsapp-button {
    font-size: 1.2em;
    padding: 12px 24px;
    background-color: #25D366; /* WhatsApp green */
    color: white;
    font-weight: bolder;
    transition: transform 0.3s ease;
    text-transform: none;
  }
  
  .whatsapp-button:hover {
    transform: scale(1.1);
    background-color: #20b358; /* Slightly darker green on hover */
  }
  </style>
  